import React from 'react';
import { Container, Typography, Button, CssBaseline, Grid, IconButton } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';
import backgroundImage from './collection/download.jfif';  // Import the background image
import logo from './collection/HodHod-yellow.png';  // Import the logo image

// Create RTL cache
const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});

// Create theme with RTL support
const theme = createTheme({
    direction: 'rtl',
    typography: {
        fontFamily: 'Arial, sans-serif',
    },
    palette: {
        primary: {
            main: '#f4a261', // Bright charcoal color
        },
        background: {
            default: '#000', // Dark background to match charcoal theme
        },
    },
});

function App() {
    return (
        <CacheProvider value={cacheRtl}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {/* Hero Section */}
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1.5 }}
                >
                    <Container
                        sx={{
                            textAlign: 'right',
                            padding: '5rem 2rem',
                            minHeight: '100vh',
                            background: `url(${backgroundImage}) no-repeat center center / cover`,  // Background image
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-end',
                            color: 'white',
                            position: 'relative',
                        }}
                    >
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',  // Dark overlay for text readability
                                zIndex: 0,
                            }}
                        />

                        {/* Ribbon Logo Section */}
                        <div style={{
                            position: 'absolute',
                            top: '1rem',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            backgroundColor: '#f4a261',
                            padding: '0.5rem 2rem',
                            borderRadius: '5px',
                            zIndex: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <img src={logo} alt="Logo" style={{ width: '200px' }} />
                        </div> 
                        <Typography
                            variant="h2"
                            component={motion.div}
                            initial={{ y: -200 }}
                            animate={{ y: 0 }}
                            transition={{ type: 'spring', stiffness: 50 }}
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '4rem',
                                zIndex: 1,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center'
                            }}
                            style={{ fontFamily: 'hala' }}
                        >
                            الهُدهُد
                        </Typography>
                         
                        <br />
                        <Typography
                            variant="h2"
                            component={motion.div}
                            initial={{ x: -200 }}
                            animate={{ x: 0 }}
                            transition={{ type: 'spring', stiffness: 50 }}
                            sx={{ fontWeight: 'bold', fontSize: '3rem', zIndex: 1 }}
                        >
                            أفضل أنواع الفحم
                        </Typography>
                        <Typography
                            variant="h5"
                            component={motion.div}
                            initial={{ x: 200 }}
                            animate={{ x: 0 }}
                            transition={{ type: 'spring', stiffness: 50, delay: 0.5 }}
                            sx={{ marginBottom: '2rem', zIndex: 1 }}
                        >
                            فحم طبيعي وفحم صناعي لتلبية جميع احتياجاتك
                        </Typography>
                        <motion.div
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            style={{ zIndex: 1 }}
                        >
                            {/*<Button*/}
                            {/*    variant="contained"*/}
                            {/*    color="primary"*/}
                            {/*    sx={{*/}
                            {/*        padding: '1rem 2.5rem',*/}
                            {/*        fontSize: '1.5rem',*/}
                            {/*        fontWeight: 'bold',*/}
                            {/*        boxShadow: '0 0 15px 5px rgba(244,162,97,0.6)',*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    اكتشف المزيد*/}
                            {/*</Button>*/}
                        </motion.div>

                        {/* Social Media Icons */}
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 1 }}
                            style={{
                                marginTop: '3rem',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                zIndex: 1,
                            }}
                        >
                            <IconButton
                                href="https://www.facebook.com/"
                                target="_blank"
                                sx={{
                                    color: 'white',
                                    '&:hover': {
                                        color: '#3b5998',
                                        transform: 'scale(1.2)',
                                        transition: '0.3s',
                                    },
                                }}
                            >
                                <FacebookIcon fontSize="large" />
                            </IconButton>
                            <IconButton
                                href="https://www.instagram.com/"
                                target="_blank"
                                sx={{
                                    color: 'white',
                                    '&:hover': {
                                        color: '#C13584',
                                        transform: 'scale(1.2)',
                                        transition: '0.3s',
                                    },
                                }}
                            >
                                <InstagramIcon fontSize="large" />
                            </IconButton>
                            <IconButton
                                href="https://api.whatsapp.com/send?phone=YOURNUMBER"
                                target="_blank"
                                sx={{
                                    color: 'white',
                                    '&:hover': {
                                        color: '#25D366',
                                        transform: 'scale(1.2)',
                                        transition: '0.3s',
                                    },
                                }}
                            >
                                <WhatsAppIcon fontSize="large" />
                            </IconButton>

                            <IconButton
                                color="inherit" 
                                href={`tel:96171521243`}
                                sx={{
                                    color: 'white',
                                    '&:hover': {
                                        color: '#25D366',
                                        transform: 'scale(1.2)',
                                        transition: '0.3s',
                                    },
                                }}
                            >
                                <PhoneIcon fontSize="large" /> 
                            </IconButton>

                        </motion.div>
                    </Container>
                </motion.div>

                {/* About Section */}
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1.5 }}
                >
                    <Container
                        sx={{
                            padding: '4rem 2rem',
                            backgroundColor: '#f5f5f5',
                            textAlign: 'center',
                        }}
                    >
                        <Typography
                            variant="h3"
                            gutterBottom
                            sx={{
                                fontWeight: 'bold',
                                marginBottom: '2rem',
                            }}
                        >
                            من نحن
                        </Typography>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={4}>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    component={motion.div}
                                    initial={{ opacity: 0 }}
                                    whileInView={{ opacity: 1 }}
                                    transition={{ duration: 1 }}
                                >
                                    نقدم لكم أفضل أنواع الفحم الطبيعي لجميع احتياجاتكم، سواء للشواء أو التدفئة.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    component={motion.div}
                                    initial={{ opacity: 0 }}
                                    whileInView={{ opacity: 1 }}
                                    transition={{ duration: 1 }}
                                >
                                    نوفر أيضًا الفحم الصناعي عالي الجودة الذي يتميز بكفاءته وطول مدة احتراقه.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    component={motion.div}
                                    initial={{ opacity: 0 }}
                                    whileInView={{ opacity: 1 }}
                                    transition={{ duration: 1 }}
                                >
                                    لدينا فحم خاص بالأرغيلة (الشيشة) لضمان تجربة مميزة لعشاق الشيشة.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </motion.div>
            </ThemeProvider>
        </CacheProvider>
    );
}

export default App;
